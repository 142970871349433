import React, { useEffect, useState, useContext } from "react";

import Link from "next/link";

import { gsap } from "gsap";
import { useSpring, animated } from "@react-spring/web";
import Modal from "react-modal";

import styles from "../styles/About.module.css";

export default function About(props: any) {
  const ctx = useContext(props.context) as any;

  const [isOpen, setOpen] = useState(false);
  const [isPathOpen, setPathOpen] = useState(false);
  const [isPanelOpen, setPanelOpen] = useState(false);

  const [isEn, setEn] = useState(true);

  function toggleAbout(): void {
    if (isOpen) {
      setPathOpen(false);
      setPanelOpen(false);
      ctx.pointIcon.display = true;
      ctx.transition.isAbout = false;

      gsap.set("html", {
        "--scroll": "rgba(255,255,255,0)",
        id: "off",
        overwrite: true,
      });

      gsap.set(".ReactModal__Content--after-open", {
        pointerEvents: "none",
      });
    } else {
      setOpen(true);
      setPanelOpen(true);
      setPathOpen(true);

      ctx.pointIcon.display = false;
    }
  }

  useEffect(() => {
    props.aboutRef.current = toggleAbout;
  }, []);

  const panelProps = useSpring({
    top: isPanelOpen ? "0" : "100vh",
    from: { top: "100vh" },
    config: {
      tension: isPanelOpen ? 170 : 340,
    },
    delay: isPanelOpen ? 50 : 0,
    onRest: () => {},
  });

  const langProps = useSpring({
    opacity: isPanelOpen ? 1 : 0,
    config: {
      duration: isPanelOpen ? 200 : 50,
    },
    from: { opacity: 0 },
  });

  const bgProps = useSpring({
    d:
      isPathOpen && isOpen
        ? "M 0, 100 L 100, 100 L 100, 0 Q 50 0, 0 0z"
        : !isPathOpen && isOpen
        ? "M 0, 100 L 100, 100 L 100,  100 Q 50 110, 0  100z"
        : "M 0, 100 L 100, 100 L 100, 150 Q 50 100, 0 150z",

    onRest: () => {
      if (isOpen && !isPathOpen) {
        setOpen(false);
      }
    },
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="Modal"
        ariaHideApp={false}
        onAfterOpen={() => {
          gsap.to("html", {
            "--scroll": "rgba(255,255,255,0.2)",
            duration: 0.5,
            delay: 1,
            id: "on",
            overwrite: true,
          });
        }}
        onAfterClose={() => {}}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0)",
            zIndex: 20000000,
          },
          content: {
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            overflow: "hidden",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            height: "100%",
          },
        }}
      >
        <div className={styles.path}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            className={styles.path}
            preserveAspectRatio="none"
          >
            <animated.path fill="#000" d={bgProps.d}></animated.path>
          </svg>
        </div>
        <animated.div className={styles.container} style={panelProps}>
          <div className={styles.title}>Media Façade Simulator</div>
          <div
            className={styles.close}
            onClick={() => {
              toggleAbout();
            }}
            onPointerEnter={() => {
              props.cursor.current.dispSmall();
            }}
            onPointerLeave={() => {
              props.cursor.current.dispMedium();
            }}
          >
            CLOSE
          </div>
          <animated.div
            className={styles.button}
            style={langProps}
            onClick={() => {
              if (isEn) {
                setEn(false);
              } else {
                setEn(true);
              }
            }}
            onPointerEnter={() => {
              props.cursor.current.dispSmall();
            }}
            onPointerLeave={() => {
              props.cursor.current.dispMedium();
            }}
          >
            <div>
              {isEn && <>JP</>}
              {!isEn && <>EN</>}
            </div>
          </animated.div>
          <h1>
            Media Façade <br />
            Simulator
          </h1>
          <div className={styles.innerContainer}>
            <p className={styles.col1}>
              {isEn && (
                <>
                  Media Façade simulator is a demo website built on the SHIFT
                  LINK system for billboard simulation. Users can change the
                  contents of the virtual images and check the images from any
                  viewpoint, as well as the sounds recorded in the real Shibuya
                  area. In the future, we will build a system that connects this
                  simulation with the real world.
                </>
              )}
              {!isEn && (
                <>
                  Media Façade
                  simulatorは、デジタルサイネージのシミュレーションをする為のSHIFT
                  LINKシステムで構築したデモサイトです。バーチャル上の渋谷の建物に仮想の映像コンテンツを表示し、ユーザーは任意の視点で映像コンテンツを変更したり、渋谷で録音した音を体験する事が出来ます。将来的にはこのシミュレーションとリアルが繋がるようなシステムを構築していきます。
                </>
              )}
            </p>

            {
              <div className={styles.columnsContainer}>
                <div className={styles.columnsWrapper}>
                  <div className={styles.col2}>
                    <h2>SHIFT LINK</h2>
                    <p>
                      <Link href="https://shiftlink.tech/" passHref>
                        <a
                          className={styles.anchor}
                          style={{ display: "inline-block" }}
                          target="_blank"
                        >
                          SHIFT LINK
                        </a>
                      </Link>
                      &nbsp;creates interfaces for the real and virtual,
                      connecting various equipment, devices, people and
                      services.
                    </p>
                    <h2>FOLLOW US</h2>
                    <ul className={styles.social}>
                      <li>
                        <Link
                          href={"https://www.instagram.com/theshift.tokyo/"}
                          passHref
                        >
                          <a className={styles.anchor} target="_blank">
                            ig
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={"https://twitter.com/theshift_tokyo"}
                          passHref
                        >
                          <a className={styles.anchor} target="_blank">
                            tw
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.col3}>
                    <h2>CREDITS</h2>
                    <ul>
                      <li>
                        Produce/ Creative Direction/ Design:&nbsp;
                        <Link href={"https://theshift.tokyo/"} passHref>
                          <a className={styles.anchor} target="_blank">
                            The Shift
                          </a>
                        </Link>
                      </li>
                      <li>
                        Web Development / Field Recording:&nbsp;
                        <Link href={"https://aircord.co.jp/"} passHref>
                          <a className={styles.anchor} target="_blank">
                            aircord
                          </a>
                        </Link>
                      </li>
                      <li>
                        3D City Model Data (Shibuya, Tokyo):&nbsp;
                        <Link
                          href={"https://www.mlit.go.jp/plateau/opendata/"}
                          passHref
                        >
                          <a className={styles.anchor} target="_blank">
                            PLATEAU
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            }
          </div>
        </animated.div>
      </Modal>
    </>
  );
}
