import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import { gsap } from "gsap";
import styles from "../styles/MouseFollower.module.css";

export default forwardRef(function MouseFollower(props: any, ref: any) {
  const ballRef = useRef<HTMLDivElement>(null);
  const dotRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const xSet = gsap.quickSetter(ballRef.current, "x", "px");
    const ySet = gsap.quickSetter(ballRef.current, "y", "px");

    gsap.set(ballRef.current, {
      xPercent: -50,
      yPercent: -50,
    });

    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.35;

    window.addEventListener("pointermove", (e) => {
      mouse.x = e.x;
      mouse.y = e.y;

      if (!ballRef.current) return;
      ballRef.current.style.opacity = "1";
    });

    gsap.ticker.add((self) => {
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;

      xSet(pos.x);
      ySet(pos.y);
    });

    return () => {};
  }, []);

  useEffect(() => {
    gsap.set(ballRef.current, {
      duration: 0.3,
      ease: "expo.out",
      width: "251px",
      height: "251px",
    });

    gsap.to(labelRef.current, {
      onStart: () => {
        if (labelRef.current == null) return;
        labelRef.current.innerText = "start";
      },
      duration: 0.3,
      ease: "expo.out",
      opacity: 1,
    });

    gsap.to(dotRef.current, {
      duration: 0.3,
      ease: "expo.out",
      width: "0px",
      height: "0px",
    });
  }, []);

  useImperativeHandle(ref, () => {
    return {
      dispDrag(): void {
        gsap.to(ballRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "111px",
          height: "111px",
        });

        gsap.to(dotRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "0px",
          height: "0px",
        });

        gsap.to(labelRef.current, {
          onStart: () => {
            if (labelRef.current == null) return;
            labelRef.current.innerText = "drag";
          },
          duration: 0.3,
          ease: "expo.out",
          opacity: 1,
        });

        gsap.to(arrowRef.current, {
          duration: 0.3,
          ease: "expo.out",
          opacity: 0,
        });
      },
      dispSlider(): void {
        gsap.to(ballRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "29px",
          height: "29px",
        });

        gsap.to(dotRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "0px",
          height: "0px",
        });

        gsap.to(labelRef.current, {
          duration: 0.3,
          ease: "expo.out",
          opacity: 0,
        });

        gsap.to(arrowRef.current, {
          duration: 0.3,
          ease: "expo.out",
          opacity: 1,
        });
      },
      dispMedium(): void {
        gsap.to(ballRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "45px",
          height: "45px",
        });

        gsap.to(dotRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "6px",
          height: "6px",
        });

        gsap.to(labelRef.current, {
          duration: 0.3,
          ease: "expo.out",
          opacity: 0,
        });

        gsap.to(arrowRef.current, {
          duration: 0.3,
          ease: "expo.out",
          opacity: 0,
        });
      },
      dispSmall(): void {
        gsap.to(ballRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "29px",
          height: "29px",
        });

        gsap.to(dotRef.current, {
          duration: 0.3,
          ease: "expo.out",
          width: "6px",
          height: "6px",
        });

        gsap.to(labelRef.current, {
          duration: 0.3,
          ease: "expo.out",
          opacity: 0,
        });

        gsap.to(arrowRef.current, {
          duration: 0.3,
          ease: "expo.out",
          opacity: 0,
        });
      },
    };
  });

  return (
    <div className={`${styles.mouseFollower} ${"cursor"}`} ref={ballRef}>
      <div className={styles.dot} ref={dotRef}></div>
      <div className={styles.label} ref={labelRef}>
        start
      </div>

      <div className={styles.arrow} ref={arrowRef}>
        <svg
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 9 10.39"
        >
          <path d="M0,5.2L9,0V10.39L0,5.2Z" />
        </svg>

        <svg
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 9 10.39"
        >
          <path d="M0,5.2L9,0V10.39L0,5.2Z" />
        </svg>
      </div>
    </div>
  );
});
