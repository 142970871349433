import React, { useMemo, useEffect, useState, useContext } from "react";
import { animated, useTrail } from "@react-spring/web";
import styles from "../styles/ButtonAudio.module.css";

export default function ButtonAudio(props: any) {
  const [isOn, setOn] = useState(true);

  const ctx = useContext(props.context) as any;

  function Trail({ children }: any) {
    const items = React.Children.toArray(children);

    const trail = useTrail(items.length, {
      from: { height: isOn ? "3px" : "3px" },
      to: { height: isOn ? "12px" : "3px" },
      loop: { reverse: true },
    });
    return (
      <>
        {trail.map(({ height }, index) => (
          <animated.div key={index} style={{ height }}>
            {items[index]}
          </animated.div>
        ))}
      </>
    );
  }

  const MemoedTrail = useMemo(() => {
    return (
      <Trail>
        <div className={styles.bar1}></div>
        <div className={styles.bar2}></div>
        <div className={styles.bar3}></div>
        <div className={styles.bar4}></div>
        <div className={styles.bar5}></div>
      </Trail>
    );
  }, [isOn]);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.button}
          onClick={() => {
            props.toggleAudio.current();
            if (isOn) {
              setOn(false);
            } else {
              setOn(true);
            }
          }}
          onPointerEnter={() => {
            props.cursor.current.dispSmall();
          }}
          onPointerLeave={() => {
            if (!ctx.transition.isZoom) {
              props.cursor.current.dispDrag();
            } else {
              props.cursor.current.dispMedium();
            }
          }}
        >
          <div className={styles.wrapper}>{MemoedTrail}</div>
        </div>
      </div>
    </>
  );
}
