import { Suspense, useRef, useState, useEffect, createContext } from "react";

import type { NextPage } from "next";
import Head from "next/head";

import { Canvas, useLoader } from "@react-three/fiber";
import { Environment, Stats } from "@react-three/drei";
import Model from "../components/Models";
import {
  EffectComposer,
  Bloom,
  Vignette,
  ToneMapping,
  LUT,
  SMAA,
} from "@react-three/postprocessing";

import { KernelSize, LUTCubeLoader, BlendFunction } from "postprocessing";

import dynamic from "next/dynamic";
const Loader = dynamic(() => import("../components/Loader"), {
  ssr: false,
});

import { useSpring, animated } from "@react-spring/web";
import { isMobile } from "react-device-detect";
import { getGPUTier } from "detect-gpu";

import Splash from "../components/Splash";
import Header from "../components/Header";
import About from "../components/About";
import Place from "../components/Place";
import ButtonAudio from "../components/ButtonAudio";
import ButtonDayNight from "../components/ButtonDayNight";
import ButtonCapture from "../components/ButtonCapture";
import Panel from "../components/Panel";
import MouseFollower from "../components/MouseFollower";

import styles from "../styles/Home.module.css";

interface CursorHandler {
  dispDrag(): void;
  dispSlider(): void;
  dispMedium(): void;
  dispSmall(): void;
}

const uiValues = {
  sliderA: {
    val0: 1,
    val1: 0.5,
    val2: 1,
  },
  sliderB: {
    val0: 0,
    val1: 0.5,
    val2: 1,
  },
  sliderC: {
    val0: 1,
    val1: 2.2,
    val2: 1,
  },

  pointIcon: {
    display: false,
  },

  transition: {
    isSplash: true,
    isZoom: false,
    isZoomEnd: false,
    isAbout: false,
    isVideoLoaded: false,
  },
};

function Grading() {
  const texture3D = useLoader(LUTCubeLoader, "/lut.txt") as any;

  return !isMobile ? <LUT lut={texture3D} /> : null;
}

export const UIContext = createContext(uiValues);

const Home: NextPage = () => {
  function EffectBloom1() {
    return !isMobile ? (
      <Bloom
        kernelSize={3}
        luminanceThreshold={0}
        luminanceSmoothing={0.4}
        intensity={isNight ? 1 : 0}
      />
    ) : null;
  }

  function EffectBloom2() {
    return !isMobile ? (
      <Bloom
        kernelSize={KernelSize.HUGE}
        luminanceThreshold={0}
        luminanceSmoothing={0}
        intensity={0.5}
      />
    ) : null;
  }

  const [gpuTier, setGpuTier] = useState(0);
  const [lightGfx, setLightGfx] = useState(false);

  const [gfxIdx, setGfxIdx] = useState(2);

  useEffect(() => {
    if (isMobile) {
      //setLightGfx(true);
      setGfxIdx(1);
    }

    (async () => {
      const gpuTier = await getGPUTier();
      setGpuTier(gpuTier.tier);

      if (gpuTier.tier == 0) {
        setLightGfx(true);
        setGfxIdx(0);
      }
    })();
  }, []);

  const cursorRef = useRef({} as CursorHandler);
  const splashRef = useRef(null);

  const openRef = useRef(null);
  const [open, setOpen] = useState(false);

  const props2 = useSpring({
    opacity: open ? 1 : 0,
  });

  const cameraRef = useRef<HTMLCanvasElement>(null);

  const changePattern = useRef(null);
  const cameraDefaultAngle = useRef(null);
  const cameraStart = useRef(null);

  const toggleAudio = useRef(null);
  const toggleDefaultAngle = useRef(null);
  const toggleCapture = useRef(null);
  const toggleVideo = useRef(null);

  const captureImage = useRef(null);

  function showHUDElements() {
    setOpen(true);
  }

  const zoomRef = useRef(false);
  const aboutRef = useRef(null);

  const [isNight, setNight] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  return (
    <>
      {/*<Loader splashRef={splashRef} />*/}

      <div id="container" className={styles.container} ref={containerRef}>
        <Head>
          <meta charSet={"UTF-8"} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/assets/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/assets/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/assets/favicon-16x16.png"
          />
          <link rel="manifest" href="/assets/site.webmanifest" />
          <link
            rel="mask-icon"
            href="/assets/safari-pinned-tab.svg"
            color="#000000"
          />
          <meta name="theme-color" content="#000000" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={"/assets/ogp.jpg"} />
          <meta property="og:image:width" content={"1280px"} />
          <meta property="og:image:height" content={"720px"} />

          <title>SHIFT LINK | Media Façade Simulator</title>
          <meta
            name="description"
            content="SHIFT LINK | Media Façade Simulator"
          />
        </Head>

        <Canvas
          ref={cameraRef}
          camera={{
            fov: 80,
            position: [
              24.682166724477618, 151.08654013835573, 159.87200417587854,
            ],
            quaternion: [
              -0.3052573070623509, 0.05882724215032874, 0.018897337906766037,
              0.9502632386271005,
            ],

            far: 10000,
            near: 1.5,
          }}
          //dpr={gpuTier > 0 ? [1, 1.5] : 0.75}
          dpr={gpuTier > 0 ? [1, 1.5] : 1}
          //gl={{ alpha: false, antialias: lightGfx ? true : false }}
          gl={{ alpha: false, antialias: false, preserveDrawingBuffer: true }}
        >
          <fog attach="fog" args={["#000000", 0.01, 500]} />

          <ambientLight intensity={10} />
          <Suspense fallback={null}>
            <Model
              changePattern={changePattern}
              cameraDefaultAngle={cameraDefaultAngle}
              cameraStart={cameraStart}
              cursor={cursorRef}
              toggleAudio={toggleAudio}
              toggleDefaultAngle={toggleDefaultAngle}
              zoomRef={zoomRef}
              isNight={isNight}
              containerRef={containerRef}
              captureImage={captureImage}
              toggleCapture={toggleCapture}
              toggleVideo={toggleVideo}
            />
          </Suspense>

          <Environment files="/potsdamer_platz_1k.hdr" /*preset="city"*/ />

          {!lightGfx && (
            <EffectComposer multisampling={4}>
              <Grading />
              <SMAA />

              <ToneMapping
                blendFunction={BlendFunction.NORMAL}
                adaptive={true}
                resolution={256}
                middleGrey={isNight ? 199 : 5}
                maxLuminance={100.0}
                averageLuminance={1.0}
                adaptationRate={12.0}
              />

              <EffectBloom1 />
              <EffectBloom2 />

              <Vignette eskil={false} offset={0.1} darkness={0.95} />
            </EffectComposer>
          )}
          {lightGfx && (
            <EffectComposer multisampling={4}>
              <SMAA />
              <Vignette eskil={false} offset={0.1} darkness={0.95} />
            </EffectComposer>
          )}
          {/*<Stats />*/}
        </Canvas>
      </div>

      <animated.div style={props2}>
        <About aboutRef={aboutRef} context={UIContext} cursor={cursorRef} />
        <Header aboutRef={aboutRef} context={UIContext} cursor={cursorRef} />
        <Place context={UIContext} cursor={cursorRef} />
        <ButtonAudio
          cursor={cursorRef}
          toggleAudio={toggleAudio}
          context={UIContext}
        />
        <ButtonDayNight
          context={UIContext}
          cursor={cursorRef}
          setNight={setNight}
        />
        <ButtonCapture
          captureImage={captureImage}
          cursor={cursorRef}
          toggleCapture={toggleCapture}
        />
        <Panel
          changePattern={changePattern}
          cameraDefaultAngle={cameraDefaultAngle}
          cursor={cursorRef}
          toggleDefaultAngle={toggleDefaultAngle}
          toggleCapture={toggleCapture}
          zoomRef={zoomRef}
          toggleVideo={toggleVideo}
        />
      </animated.div>
      <Splash
        splashRef={splashRef}
        cameraStart={cameraStart}
        showHUD={showHUDElements}
        context={UIContext}
        cursor={cursorRef}
        toggleAudio={toggleAudio}
      />

      <MouseFollower ref={cursorRef} />
    </>
  );
};

export default Home;
