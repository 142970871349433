import * as SliderPrimitive from "@radix-ui/react-slider";
import { styled } from "@stitches/react";

export const Slider = styled(SliderPrimitive.Root, {
  position: "relative",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  touchAction: "none",
  width: "100%",

  '&[data-orientation="horizontal"]': {
    height: 17,
  },

  '&[data-orientation="vertical"]': {
    flexDirection: "column",
    width: 17,
    height: 100,
  },
});
export const SliderTrack = styled(SliderPrimitive.Track, {
  backgroundColor: "#fff",
  position: "relative",
  flexGrow: 1,
  borderRadius: "9999px",

  '&[data-orientation="horizontal"]': { height: 1 },
  '&[data-orientation="vertical"]': { width: 3 },
});
export const SliderThumb = styled(SliderPrimitive.Thumb, {
  all: "unset",
  display: "block",
  width: 17,
  height: 17,
  backgroundColor: "black",
  border: "solid",
  borderColor: "#fff",
  borderWidth: "1px",
  borderRadius: "50%",
});
