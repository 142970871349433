import React, { useRef, useEffect, useState, useContext } from "react";
import dynamic from "next/dynamic";

const MediaQuery = dynamic(
  () => {
    return import("react-responsive");
  },
  { ssr: false }
);

import { useSpring, animated, useTrail, easings } from "@react-spring/web";

import styles from "../styles/Splash.module.css";

export default function Splash(props: any) {
  const ctx = useContext(props.context) as any;

  const containerRef = useRef<HTMLDivElement>(null);
  const [isStart, setStart] = useState(false);
  const [isClicked, setClicked] = useState(false);

  const DELAY_VALUE = 1000;

  const [isFinished, setFinished] = useState(false);

  function ClickToStart() {
    if (isStart && props.cameraStart.current != null) {
      setClicked(true);
      props.toggleAudio.current();
    }
  }

  const containerProps = useSpring({
    opacity: isClicked ? "0" : "1",

    onStart: () => {
      props.cameraStart.current();
      props.showHUD();
      ctx.pointIcon.display = true;
      //props.toggleAudio.current();

      props.cursor.current.dispDrag();
    },
    onRest: () => {
      if (containerRef.current == null) return;
      containerRef.current.style.display = "none";

      setFinished(true);
    },
  });

  const subcontainerProps = useSpring({
    background: "rgba(0,0,0,0.5)",
    from: "rgba(0,0,0,1)",
    delay: DELAY_VALUE,
  });

  const lineProps = useSpring({
    width: isStart ? "100%" : "0%",
    from: "0%",
    delay: DELAY_VALUE,
  });

  const startProps = useSpring({
    config: { duration: 1000, easing: easings.easeOutExpo },
    opacity: isStart ? 1 : 0,
    y: isStart ? "0px" : "100px",
    from: { opacity: 0, y: "1.2em" },

    delay: DELAY_VALUE + 150,
  });

  function showContainer() {
    if (containerRef.current != null) {
      setStart(true);
    }
  }

  useEffect(() => {
    props.splashRef.current = showContainer;
    showContainer();
  }, []);

  function Trail({ children }: any) {
    const items = React.Children.toArray(children);
    const trail = useTrail(items.length, {
      config: { mass: 5, tension: 2000, friction: 200 },
      opacity: isStart ? 1 : 0,
      y: isStart ? "0px" : "100px",
      from: { opacity: 0, y: "1.2em" },
      delay: DELAY_VALUE,
    });

    return (
      <>
        {trail.map(({ y, ...style }, index) => (
          <animated.div key={index} className={styles.trailsText} style={style}>
            <animated.div style={{ y }}>{items[index]}</animated.div>
          </animated.div>
        ))}
      </>
    );
  }

  return (
    <>
      {!isClicked && (
        <animated.div
          ref={containerRef}
          style={containerProps}
          onClick={() => {
            ClickToStart();
            //props.toggleAudio.current();
          }}
        >
          <animated.div className={styles.container} style={subcontainerProps}>
            <div
              onClick={() => {
                //ClickToStart();
              }}
            >
              <h1 className={styles.title}>
                <MediaQuery minWidth={1361}>
                  <Trail>
                    <span>Media Façade </span>
                    <span>Simulator</span>
                  </Trail>
                </MediaQuery>
                <MediaQuery maxWidth={1360}>
                  <Trail>
                    <span>Media</span>
                    <span>Façade </span>
                    <span>Simulator</span>
                  </Trail>
                </MediaQuery>
              </h1>
              <div className={styles.wrapper}>
                <animated.div className={styles.line} style={lineProps} />
                <div className={styles.start}>
                  <animated.div style={startProps}>start</animated.div>
                </div>
              </div>
            </div>
            <p className={styles.text}>
              <animated.span style={startProps}>
                Media Façade simulator is a demo website built on the SHIFT LINK
                system for billboard simulation. Users can adjust their own
                virtual media façade by handling the different parameters.
              </animated.span>
            </p>
          </animated.div>
        </animated.div>
      )}
    </>
  );
}
