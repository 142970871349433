import React, { useRef, useEffect, useState, useContext } from "react";

import { useSpring, animated, easings } from "@react-spring/web";
import { UIContext } from "../pages/index";

import styles from "../styles/Button.module.css";

export const Button = (props: any) => {
  const bgRef = useRef<HTMLDivElement>(null);

  const buttonProps = useSpring({
    y: props.isOn ? "0%" : "100%",
    config: {
      easing: easings.easeOutCirc,
      duration: 500,
    },
    onStart: () => {
      if (props.isOn) {
        if (bgRef.current == null) return;
        bgRef.current.style.display = "initial";
      }
    },
    onRest: () => {
      if (!props.isOn) {
        if (bgRef.current == null) return;
        bgRef.current.style.display = "none";
      }
    },
  });

  const textProps = useSpring({
    color: props.isOn ? "#000" : "#fff",
  });

  useEffect(() => {
    if (!props.isOn) {
      if (bgRef.current == null) return;
      bgRef.current.style.display = "none";
    } else {
      if (bgRef.current == null) return;
      bgRef.current.style.display = "initial";
    }
  }, []);

  return (
    <>
      <animated.div className={`${styles.button} ${styles.buttonType}`}>
        <div className={styles.border}></div>

        <animated.div className={styles.bg} style={buttonProps} ref={bgRef} />
        <animated.div className={styles.text} style={textProps}>
          type {props.idx}
        </animated.div>
      </animated.div>
    </>
  );
};

export const ButtonDefaultAngle = (props: any) => {
  const ctx = useContext(UIContext) as any;

  const [isOn, setOn] = useState(false);
  const bgRef = useRef<HTMLDivElement>(null);

  const buttonDefaultAngleProps = useSpring({
    y: isOn ? "100%" : "0%",
    config: {
      easing: easings.easeOutCirc,
      duration: 500,
    },

    onStart: () => {
      if (!isOn) {
        if (bgRef.current == null) return;
        bgRef.current.style.display = "initial";
      }
    },
    onRest: () => {
      if (isOn) {
        if (bgRef.current == null) return;
        bgRef.current.style.display = "none";
      }
    },
  });

  const textDefaultAngleProps = useSpring({
    color: isOn ? "#fff" : "#000",
  });

  function toggleDefaultAngle() {
    setOn(true);
  }

  useEffect(() => {
    props.toggleDefaultAngle.current = toggleDefaultAngle;
  }, []);

  return (
    <>
      <animated.div
        className={`${styles.button} ${styles.buttonDefaultAngle}`}
        onClick={() => {
          if (ctx.transition.isZoom) {
            setOn(false);
            props.toggleCapture.current(false);
          }
        }}
      >
        <div className={styles.borderDefaultAngle}></div>
        <animated.div
          className={styles.bgDefaultAngle}
          style={buttonDefaultAngleProps}
          ref={bgRef}
        />
        <animated.div className={styles.text} style={textDefaultAngleProps}>
          default angle
        </animated.div>
      </animated.div>
    </>
  );
};
