import React, { useContext } from "react";
import Router from "next/router";
import styles from "../styles/Header.module.css";

export default function Header(props: any) {
  const ctx = useContext(props.context) as any;

  return (
    <>
      <header className={styles.container}>
        <h1
          onClick={() => {
            Router.reload();
          }}
          onPointerEnter={() => {
            props.cursor.current.dispSmall();
          }}
          onPointerLeave={() => {
            if (!ctx.transition.isZoom) {
              props.cursor.current.dispDrag();
            } else {
              props.cursor.current.dispMedium();
            }
          }}
        >
          Media Façade Simulator
        </h1>
        <span
          className={styles.about}
          onClick={() => {
            props.aboutRef.current();
            ctx.pointIcon.display = false;
            ctx.transition.isAbout = true;
          }}
          onPointerEnter={() => {
            props.cursor.current.dispSmall();
          }}
          onPointerLeave={() => {
            if (!ctx.transition.isZoom && !ctx.transition.isAbout) {
              props.cursor.current.dispDrag();
            } else {
              props.cursor.current.dispMedium();
            }
          }}
        >
          About
        </span>
      </header>
    </>
  );
}
