import React, { useState, useContext } from "react";

import { Slider, SliderTrack, SliderThumb } from "../components/Slider";
import { Button, ButtonDefaultAngle } from "../components/Button";
import { animated } from "@react-spring/web";
import { UIContext } from "../pages/index";

import styles from "../styles/Panel.module.css";

export default function Panel(props: any) {
  const [currentIdx, setCurrentIdx] = useState(0);

  const ctx = useContext(UIContext) as any;

  const [button0On, setButton0On] = useState(true);
  const [button1On, setButton1On] = useState(false);
  const [button2On, setButton2On] = useState(false);

  return (
    <>
      <animated.div className={styles.container}>
        <span className={styles.buttonTitle}>visualization types</span>

        <ul className={styles.buttons}>
          <li
            onClick={() => {
              props.changePattern.current(0);
              setCurrentIdx(0);

              setButton0On(true);
              setButton1On(false);
              setButton2On(false);
            }}
            onPointerEnter={() => {
              props.cursor.current.dispSmall();
            }}
            onPointerLeave={() => {
              if (!ctx.transition.isZoom) {
                props.cursor.current.dispDrag();
              } else {
                props.cursor.current.dispMedium();
              }
            }}
          >
            <Button idx={1} isOn={button0On} />
          </li>
          <li
            onClick={() => {
              props.changePattern.current(1);
              setCurrentIdx(1);

              setButton0On(false);
              setButton1On(true);
              setButton2On(false);
            }}
            onPointerEnter={() => {
              props.cursor.current.dispSmall();
            }}
            onPointerLeave={() => {
              if (!ctx.transition.isZoom) {
                props.cursor.current.dispDrag();
              } else {
                props.cursor.current.dispMedium();
              }
            }}
          >
            <Button idx={2} isOn={button1On} />
          </li>
          <li
            onClick={() => {
              props.changePattern.current(2);
              setCurrentIdx(2);

              setButton0On(false);
              setButton1On(false);
              setButton2On(true);
              props.toggleVideo.current();
            }}
            onPointerEnter={() => {
              props.cursor.current.dispSmall();
            }}
            onPointerLeave={() => {
              if (!ctx.transition.isZoom) {
                props.cursor.current.dispDrag();
              } else {
                props.cursor.current.dispMedium();
              }
            }}
          >
            <Button idx={3} isOn={button2On} />
          </li>
        </ul>
        <div className={styles.slidersContainer}>
          <div className={styles.sliderContainer}>
            <span className={styles.sliderTitle}>visualization editors</span>

            {/********  0 ********/}

            <div
              className={
                currentIdx == 0
                  ? `${styles.sliderWrapper} ${styles.sliderOn}`
                  : `${styles.sliderWrapper} ${styles.sliderOff}`
              }
            >
              <label>Hue</label>
              <Slider
                onValueChange={(e) => {
                  ctx.sliderA.val0 = e[0];
                }}
                name={"hue"}
                min={0}
                max={1}
                step={0.01}
                defaultValue={[0]}
                onPointerEnter={() => {
                  props.cursor.current.dispSlider();
                }}
                onPointerLeave={() => {
                  if (!ctx.transition.isZoom) {
                    props.cursor.current.dispDrag();
                  } else {
                    props.cursor.current.dispMedium();
                  }
                }}
              >
                <SliderTrack></SliderTrack>
                <SliderThumb></SliderThumb>
              </Slider>
            </div>

            <div
              className={
                currentIdx == 1
                  ? `${styles.sliderWrapper} ${styles.sliderOn}`
                  : `${styles.sliderWrapper} ${styles.sliderOff}`
              }
            >
              <label>Hue</label>
              <Slider
                onValueChange={(e) => {
                  ctx.sliderB.val0 = e[0];
                }}
                name={"hue"}
                min={0}
                //max={0.8}
                max={6.28318530718}
                step={0.01}
                defaultValue={[0]}
                onPointerEnter={() => {
                  props.cursor.current.dispSlider();
                }}
                onPointerLeave={() => {
                  if (!ctx.transition.isZoom) {
                    props.cursor.current.dispDrag();
                  } else {
                    props.cursor.current.dispMedium();
                  }
                }}
              >
                <SliderTrack></SliderTrack>
                <SliderThumb></SliderThumb>
              </Slider>
            </div>
            <div
              className={
                currentIdx == 2
                  ? `${styles.sliderWrapper} ${styles.sliderOn}`
                  : `${styles.sliderWrapper} ${styles.sliderOff}`
              }
            >
              <label>Hue</label>
              <Slider
                onValueChange={(e) => {
                  ctx.sliderC.val0 = e[0];
                }}
                name={"hue"}
                min={0}
                max={1}
                step={0.01}
                defaultValue={[1]}
                onPointerEnter={() => {
                  props.cursor.current.dispSlider();
                }}
                onPointerLeave={() => {
                  if (!ctx.transition.isZoom) {
                    props.cursor.current.dispDrag();
                  } else {
                    props.cursor.current.dispMedium();
                  }
                }}
              >
                <SliderTrack></SliderTrack>
                <SliderThumb></SliderThumb>
              </Slider>
            </div>
          </div>

          {/******** 1 ********/}

          <div
            className={
              currentIdx == 0
                ? `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOn} ${styles.sliderOffset}`
                : `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOff}`
            }
          >
            <label>Speed</label>
            <Slider
              onValueChange={(e) => {
                ctx.sliderA.val1 = e[0];
              }}
              name={"speed"}
              min={0}
              max={1}
              step={0.01}
              defaultValue={[0.5]}
              onPointerEnter={() => {
                props.cursor.current.dispSlider();
              }}
              onPointerLeave={() => {
                if (!ctx.transition.isZoom) {
                  props.cursor.current.dispDrag();
                } else {
                  props.cursor.current.dispMedium();
                }
              }}
            >
              <SliderTrack></SliderTrack>
              <SliderThumb></SliderThumb>
            </Slider>
          </div>
          <div
            className={
              currentIdx == 1
                ? `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOn} ${styles.sliderOffset}`
                : `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOff}`
            }
          >
            <label>Speed</label>
            <Slider
              onValueChange={(e) => {
                ctx.sliderB.val1 = e[0];
              }}
              name={"speed"}
              min={0}
              max={1}
              step={0.01}
              defaultValue={[0.5]}
              onPointerEnter={() => {
                props.cursor.current.dispSlider();
              }}
              onPointerLeave={() => {
                if (!ctx.transition.isZoom) {
                  props.cursor.current.dispDrag();
                } else {
                  props.cursor.current.dispMedium();
                }
              }}
            >
              <SliderTrack></SliderTrack>
              <SliderThumb></SliderThumb>
            </Slider>
          </div>
          <div
            className={
              currentIdx == 2
                ? `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOn} ${styles.sliderOffset}`
                : `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOff}`
            }
          >
            <label>Gamma</label>
            <Slider
              onValueChange={(e) => {
                ctx.sliderC.val1 = e[0];
              }}
              name={"gamma"}
              min={1.5}
              max={4}
              step={0.01}
              defaultValue={[2.2]}
              onPointerEnter={() => {
                props.cursor.current.dispSlider();
              }}
              onPointerLeave={() => {
                if (!ctx.transition.isZoom) {
                  props.cursor.current.dispDrag();
                } else {
                  props.cursor.current.dispMedium();
                }
              }}
            >
              <SliderTrack></SliderTrack>
              <SliderThumb></SliderThumb>
            </Slider>
          </div>

          {/******** 2 ********/}

          <div
            className={
              currentIdx == 0
                ? `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOn} ${styles.sliderOffset}`
                : `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOff}`
            }
          >
            <label>DIMMER</label>
            <Slider
              onValueChange={(e) => {
                ctx.sliderA.val2 = e[0];
              }}
              name={"dimmer"}
              min={0}
              max={1}
              step={0.01}
              defaultValue={[1]}
              onPointerEnter={() => {
                props.cursor.current.dispSlider();
              }}
              onPointerLeave={() => {
                if (!ctx.transition.isZoom) {
                  props.cursor.current.dispDrag();
                } else {
                  props.cursor.current.dispMedium();
                }
              }}
            >
              <SliderTrack></SliderTrack>
              <SliderThumb></SliderThumb>
            </Slider>
          </div>
          <div
            className={
              currentIdx == 1
                ? `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOn} ${styles.sliderOffset}`
                : `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOff}`
            }
          >
            <label>DIMMER</label>
            <Slider
              onValueChange={(e) => {
                ctx.sliderB.val2 = e[0];
              }}
              name={"dimmer"}
              min={0}
              max={1}
              step={0.01}
              defaultValue={[1]}
              onPointerEnter={() => {
                props.cursor.current.dispSlider();
              }}
              onPointerLeave={() => {
                if (!ctx.transition.isZoom) {
                  props.cursor.current.dispDrag();
                } else {
                  props.cursor.current.dispMedium();
                }
              }}
            >
              <SliderTrack></SliderTrack>
              <SliderThumb></SliderThumb>
            </Slider>
          </div>

          <div
            className={
              currentIdx == 2
                ? `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOn} ${styles.sliderOffset}`
                : `${styles.sliderContainer} ${styles.sliderWrapper} ${styles.sliderOff}`
            }
          >
            <label>DIMMER</label>
            <Slider
              onValueChange={(e) => {
                ctx.sliderC.val2 = e[0];
              }}
              name={"dimmer"}
              min={0}
              max={1}
              step={0.01}
              defaultValue={[1]}
              onPointerEnter={() => {
                props.cursor.current.dispSlider();
              }}
              onPointerLeave={() => {
                if (!ctx.transition.isZoom) {
                  props.cursor.current.dispDrag();
                } else {
                  props.cursor.current.dispMedium();
                }
              }}
            >
              <SliderTrack></SliderTrack>
              <SliderThumb></SliderThumb>
            </Slider>
          </div>
        </div>
        <div
          onClick={() => {
            props.cameraDefaultAngle.current();
          }}
          onPointerEnter={() => {
            props.cursor.current.dispSmall();
          }}
          onPointerLeave={() => {
            if (!ctx.transition.isZoomEnd) {
              props.cursor.current.dispDrag();
            } else {
              props.cursor.current.dispMedium();
            }
          }}
        >
          <ButtonDefaultAngle
            toggleDefaultAngle={props.toggleDefaultAngle}
            toggleCapture={props.toggleCapture}
            cursor={props.cursor}
          />
        </div>
      </animated.div>
    </>
  );
}
