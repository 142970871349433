import React, { useState, useContext } from "react";

import { useSpring, animated } from "@react-spring/web";
import { isMobile } from "react-device-detect";

import styles from "../styles/Place.module.css";

export default function Place(props: any) {
  const ctx = useContext(props.context) as any;

  const [isOn, setOn] = useState(false);

  const textProps = useSpring({
    opacity: isOn ? "1" : "0",
    x: isOn ? "0%" : "-100%",
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div
            onClick={() => {
              if (isMobile) {
                if (isOn) {
                  setOn(false);
                } else {
                  setOn(true);
                }
              }
            }}
            onPointerEnter={() => {
              if (!isMobile) {
                setOn(true);
                props.cursor.current.dispSmall();
              }
            }}
            onPointerLeave={() => {
              if (!isMobile) {
                setOn(false);
                if (!ctx.transition.isZoom) {
                  props.cursor.current.dispDrag();
                } else {
                  props.cursor.current.dispMedium();
                }
              }
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.49 28.93">
              <path
                stroke="#fff"
                d="M2.24239 11.3447L7.51603 20C8.57076 18.1535 11.3833 13.8374 12.7896 11.3447C14.3522 8.57496 14.4539 6.06926 12.7896 3.72799C10.3286 0.265839 5.05498 -0.0803679 2.24238 3.72798C-0.00769854 6.77466 1.30486 10.0752 2.24239 11.3447Z"
              />
              <circle cx="7.5" cy="7.5" r="4" stroke="white" />
            </svg>
          </div>
          <p className={styles.text}>
            <animated.span style={textProps}>
              Shibuya, <br />
              Tokyo
            </animated.span>
          </p>
        </div>
      </div>
    </>
  );
}
