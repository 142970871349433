import React, { useState, useContext } from "react";
import { useSpring, animated, easings } from "@react-spring/web";
import styles from "../styles/ButtonDayNight.module.css";

export default function ButtonDayNight(props: any) {
  const [isNight, setNight] = useState(true);

  const ctx = useContext(props.context) as any;

  const fillProps = useSpring({
    fill: isNight ? "rgba(0,0,0,1)" : "rgba(0,0,0,0)",
    strokeWidth: isNight ? "0" : "1",
    stroke: isNight ? "#000" : "#fff",
    config: {
      easing: easings.easeOutCirc,
      duration: 500,
    },
  });

  const fillDayProps = useSpring({
    fill: isNight ? "#fff" : "#000",
    config: {
      easing: easings.easeOutCirc,
      duration: 500,
    },
  });

  const strokeDayProps = useSpring({
    stroke: isNight ? "#fff" : "#000",
    config: {
      easing: easings.easeOutCirc,
      duration: 500,
    },
  });

  const buttonProps = useSpring({
    y: isNight ? "0%" : "100%",
    config: {
      easing: easings.easeOutCirc,
      duration: 500,
    },
  });

  const buttonDayProps = useSpring({
    y: isNight ? "100%" : "0%",
    config: {
      easing: easings.easeOutCirc,
      duration: 500,
    },
  });
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div
            className={styles.button}
            onClick={() => {
              setNight(false);
              props.setNight(false);
            }}
            onPointerEnter={() => {
              props.cursor.current.dispSmall();
            }}
            onPointerLeave={() => {
              if (!ctx.transition.isZoom) {
                props.cursor.current.dispDrag();
              } else {
                props.cursor.current.dispMedium();
              }
            }}
          >
            <div className={styles.border} />
            <animated.div className={styles.bg} style={buttonDayProps} />

            <div className={styles.sun}>
              {/* day */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.38 21">
                <animated.circle
                  fill="#fff"
                  cx="10.5"
                  cy="10.5"
                  r="3.75"
                  style={fillDayProps}
                />
                <animated.line
                  style={strokeDayProps}
                  x1="11"
                  x2="11"
                  y2="5.25"
                />
                <animated.line
                  style={strokeDayProps}
                  stroke="#fff"
                  x1="18.28"
                  y1="3.43"
                  x2="14.57"
                  y2="7.14"
                />
                <animated.line
                  style={strokeDayProps}
                  stroke="#fff"
                  x1="17.5"
                  y1="18.2"
                  x2="13.78"
                  y2="14.49"
                />
                <animated.line
                  style={strokeDayProps}
                  stroke="#fff"
                  x1="21.38"
                  y1="11"
                  x2="16.13"
                  y2="11"
                />
                <animated.line
                  style={strokeDayProps}
                  stroke="#fff"
                  x1="5.25"
                  y1="11"
                  y2="11"
                />
                <animated.line
                  style={strokeDayProps}
                  stroke="#fff"
                  x1="11"
                  y1="15.75"
                  x2="11"
                  y2="21"
                />
                <animated.line
                  style={strokeDayProps}
                  stroke="#fff"
                  x1="7.14"
                  y1="14.57"
                  x2="3.43"
                  y2="18.28"
                />
                <animated.line
                  style={strokeDayProps}
                  stroke="#fff"
                  x1="6.36"
                  y1="7.07"
                  x2="2.65"
                  y2="3.35"
                />
              </svg>
            </div>
          </div>

          <div
            className={styles.button}
            onClick={() => {
              setNight(true);
              props.setNight(true);
            }}
            onPointerEnter={() => {
              props.cursor.current.dispSmall();
            }}
            onPointerLeave={() => {
              if (!ctx.transition.isZoom) {
                props.cursor.current.dispDrag();
              } else {
                props.cursor.current.dispMedium();
              }
            }}
          >
            <div className={styles.border} />
            <animated.div className={styles.bg} style={buttonProps} />

            <div className={styles.moon}>
              {/* night */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.98 19.32">
                <animated.path
                  d="M8.28,.82C-7.72,5.24,3.77,27.32,17.5,15.26,8.28,16.08,2.85,9.65,8.28,.82Z"
                  style={fillProps}
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
