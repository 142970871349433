import React, { useRef, useEffect, useState, useContext, useMemo } from "react";

import { useSpring, animated } from "@react-spring/web";
import { Howl } from "howler";
import { UIContext } from "../pages/index";

import styles from "../styles/ButtonCapture.module.css";

/*
const se = new Howl({
  src: ["/shutter.webm"],
  html5: true,
  volume: 0.1,
});
*/
export default function ButtonCapture(props: any) {
  const ctx = useContext(UIContext) as any;

  const se = useMemo(
    () =>
      new Howl({
        src: ["/shutter.mp3"],
        html5: true,
        volume: 0.1,
      }),
    []
  );

  const [isOn, setOn] = useState(false);

  function toggleCapture(isToggle: any) {
    setOn(isToggle);
  }

  useEffect(() => {
    props.toggleCapture.current = toggleCapture;
  }, []);

  const [isStrobe, setStrobe] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const strobeRef = useRef<HTMLDivElement>(null);
  strobeRef.current?.style.display;
  const strobeProps = useSpring({
    opacity: isStrobe ? 0 : 1,
    display: isStrobe ? "initial" : "none",
    config: { duration: 900 },
    delay: 50,
    onRest: () => {
      setStrobe(false);
    },
  });

  const buttonProps = useSpring({
    opacity: isOn ? 1 : 0,
    onStart: () => {
      if (buttonRef.current == null) return;
      if (!isOn) {
        buttonRef.current.style.pointerEvents = "none";
      }
    },

    onRest: () => {
      if (buttonRef.current == null) return;

      if (isOn) {
        buttonRef.current.style.pointerEvents = "all";
      }
    },
  });

  const pathProps = useSpring({
    top: isOn ? "0" : "140%",
  });

  return (
    <>
      <div className={styles.container}>
        <animated.div
          ref={buttonRef}
          style={buttonProps}
          className={styles.button}
          onClick={() => {
            se.play();
            setStrobe(true);
            props.captureImage.current();
          }}
          onPointerEnter={() => {
            props.cursor.current.dispSmall();
          }}
          onPointerLeave={() => {
            if (!ctx.transition.isZoom) {
              props.cursor.current.dispDrag();
            } else {
              props.cursor.current.dispMedium();
            }
          }}
        >
          <div className={styles.border} />
          <div className={styles.bg} />
          <div className={styles.text}>capture the view</div>
          <animated.div className={styles.path} style={pathProps}>
            <svg
              fill={"#fff"}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64.83 46.9"
            >
              <path d="M49.44,7.24l-3-7.24H18.44l-3.98,7.24H0V46.9H64.83V7.24h-15.39Zm-17.82,31.41c-6.67,0-12.08-5.4-12.09-12.07,0-6.67,5.4-12.08,12.07-12.09,6.67,0,12.08,5.4,12.09,12.07,0,0,0,.01,0,.02-.01,6.66-5.41,12.06-12.07,12.07Z" />
              <path d="M40.69,26.58c0,5.01-4.07,9.08-9.08,9.08s-9.08-4.07-9.08-9.08,4.07-9.08,9.08-9.08h0c5.01,0,9.07,4.07,9.07,9.08Z" />
            </svg>
            {/*
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.83 46.9">
              <path d="M44.4,2.98l2.27,5.4,.78,1.86h14.38V43.9H3V10.24h13.24l.85-1.57,3.08-5.67h24.23m2-3H18.4l-3.94,7.24H0V46.9H64.83V7.24h-15.39l-3-7.24h-.04Z" />
              <path d="M31.62,17.5c5.01,0,9.08,4.07,9.08,9.08s-4.07,9.08-9.08,9.08-9.08-4.07-9.08-9.08,4.07-9.08,9.08-9.08h0m0-3c-6.67,0-12.08,5.4-12.09,12.07,0,6.67,5.4,12.08,12.07,12.09s12.08-5.4,12.09-12.07h0c0-6.68-5.4-12.08-12.07-12.09Z" />
            </svg>
            */}
          </animated.div>
        </animated.div>
      </div>
      <animated.div
        className={styles.strobe}
        ref={strobeRef}
        style={strobeProps}
      />
    </>
  );
}
