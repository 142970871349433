import React, { useRef, useEffect, useContext } from "react";
import { useSpring, animated } from "@react-spring/web";
import styles from "../styles/PointIcon.module.css";

export default function PointIcon(props: any) {
  const containerRef = useRef<HTMLDivElement>(null);

  const ctx = useContext(props.context) as {
    sliderA: {
      val0: number;
      val1: number;
      val2: number;
    };
    pointIcon: {
      display: boolean;
    };
  };

  const containerProp = useSpring({
    opacity: ctx.pointIcon.display ? "1" : "0",
  });

  return (
    <>
      <animated.div
        className={styles.container}
        ref={containerRef}
        style={containerProp}
        onPointerEnter={() => {
          //props.cursor.current.dispSmall();
        }}
        onPointerLeave={() => {
          //props.cursor.current.dispDrag();
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.dot}></div>
        </div>
        <div className={`${styles.circle} ${styles.c1}`}></div>
        <div className={`${styles.circle} ${styles.c2}`}></div>
        <div className={`${styles.circle} ${styles.c3}`}></div>
      </animated.div>
    </>
  );
}
